import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  Alert,
  AlertIcon,
  Spinner,
} from "@chakra-ui/react";

const RandomImageAdmin = () => {
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPath, setSelectedPath] = useState("image1");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(
        "https://australianwallstreet.com/api/randomimages"
      );
      setImages(response.data);
    } catch (error) {
      showError("Error fetching images");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!selectedFile) {
      showError("Please select a file first");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("publicPath", selectedPath);

    try {
      await axios.post(
        `https://australianwallstreet.com/api/randomimages/upload?password=${encodeURIComponent(
          password
        )}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      toast({
        title: "Upload Successful",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      fetchImages();
      setPassword("");
      setSelectedFile(null);
    } catch (error) {
      showError(error.response?.data?.error || "Upload failed");
    } finally {
      setIsLoading(false);
    }
  };

  const showError = (message) => {
    toast({
      title: "Error",
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box maxW="container.xl" mx="auto" p={4}>
      <Box bg="white" boxShadow="md" borderRadius="lg" p={6} mb={8}>
        <Heading size="lg" mb={6} color="gray.700">
          Manage Public Images
        </Heading>

        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl>
                <FormLabel>Admin Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter admin password"
                  required
                  p={0}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Target Position</FormLabel>
                <Select
                  value={selectedPath}
                  onChange={(e) => setSelectedPath(e.target.value)}
                  p={0}
                >
                  <option value="image1">Public Image 1</option>
                  <option value="image2">Public Image 2</option>
                  <option value="image3">Public Image 3</option>
                </Select>
              </FormControl>
            </SimpleGrid>

            <FormControl>
              <FormLabel>Image File</FormLabel>
              <Input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                accept="image/*"
                p={0}
              />
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isLoading}
              loadingText="Uploading..."
              size="lg"
              width={{ base: "full", md: "auto" }}
            >
              Upload Image
            </Button>
          </Stack>
        </form>
      </Box>

      <Box>
        <Heading size="md" mb={4} color="gray.700">
          Current Public Images
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
          {["image1", "image2", "image3"].map((path) => {
            const imageForPath = images
              .filter((img) => img.publicPath === path)
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

            return (
              <Box
                key={path}
                bg="white"
                borderRadius="lg"
                boxShadow="md"
                overflow="hidden"
                transition="transform 0.2s"
                _hover={{ transform: "translateY(-2px)" }}
              >
                {imageForPath ? (
                  <>
                    <Image
                      src={`https://australianwallstreet.com/api/public/${imageForPath.imagePath}`}
                      alt={path}
                      objectFit="cover"
                      w="full"
                      h="300px"
                    />
                    <Box p={4}>
                      <Text fontWeight="bold" mb={2}>
                        Position: {path}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        Uploaded:{" "}
                        {new Date(imageForPath.createdAt).toLocaleDateString()}
                      </Text>
                    </Box>
                  </>
                ) : (
                  <Box p={6} textAlign="center" bg="gray.50" minH="300px">
                    <Text color="gray.500" fontStyle="italic">
                      No image set for {path}
                    </Text>
                  </Box>
                )}
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default RandomImageAdmin;
