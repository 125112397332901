import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import React, { useRef, useState, useEffect } from "react";
import { useTheme, useLanguage } from "./ThemeContext";
import styles from "./styles/BlogSection.module.css";
import wordsJson from "./words.json";
import ProTheme from "./styles/PRO/BlogSection.module.css";
import UNSWTheme from "./styles/UNSW/BlogSection.module.css";
import MQUTheme from "./styles/MQU/BlogSection.module.css";
import UMelbTheme from "./styles/UMelb/BlogSection.module.css";
import "swiper/css";
import "./styles/Swiper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { motion } from "framer-motion";

function PastBlog() {
  const { language } = useLanguage();
  const headingContent = wordsJson.admin[language].blogSection;
  const { theme } = useTheme();

  function getThemeStyles(theme) {
    switch (theme) {
      case "Pro":
        return ProTheme;
      case "UNSW":
        return UNSWTheme;
      case "UMelb":
        return UMelbTheme;
      case "MQU":
        return MQUTheme;
      default:
        return ProTheme;
    }
  }

  const themeStyles = getThemeStyles(theme);
  const wordsData = wordsJson[theme][language].blogSection;
  const [blogs, setBlog] = useState([]);

  const [selectedTypes, setSelectedTypes] = useState([]);

  const fetchBlogs = (url, filters = {}) => {
    axios
      .get(url, { params: filters })
      .then((response) => {
        setBlog(
          response.data.map((blog, index) => ({ ...blog, Id: index + 1 }))
        );
      })
      .catch((error) => {
        console.error("Failed to fetch blogs:", error);
        setBlog([]); // Set an empty array in case of an error
      });
  };

  useEffect(() => {
    let url = "https://australianwallstreet.com/api/blog/pro";
    switch (theme) {
      case "Pro":
        url = "https://australianwallstreet.com/api/blog/pro";
        break;
      case "UNSW":
        url = "https://australianwallstreet.com/api/blog/unsw";

        break;
      case "UMelb":
        url = "https://australianwallstreet.com/api/blog/melb";
        break;
      default:
        url = "https://australianwallstreet.com/api/blog/pro";

    }
    const filters = {};
    if (selectedTypes.length > 0) {
      filters.categories = selectedTypes.join(",");
    }
    fetchBlogs(url, filters);
  }, [theme, selectedTypes]);

  const [loadedBlogIndexStart, setLoadedBlogIndexStart] = useState(0);
  const [loadedBlogIndexEnd, setLoadedBlogIndexEnd] = useState(2);
  const [loadedBlogIndexCurr, setLoadedBlogIndexCurr] = useState(0);
  const displayedBlog = blogs.slice(
    Math.max(loadedBlogIndexStart, 0),
    Math.min(loadedBlogIndexEnd, blogs.length)
  );

  /////////////////// Function for page navigation ///////////////////////
  // Functions for navigation, only 3 slide at a time, always on middle slide
  var systemSkip = false;
  const swiperRef = useRef(null);
  const goToSlide = (number, speed = 0.9) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(number, speed);
    }
  };

  const skipToBlog = (id) => {
    if (loadedBlogIndexCurr === 0) {
      systemSkip = true;
    }

    if (id === 1) {
      setLoadedBlogIndexStart(0);
      setLoadedBlogIndexEnd(2);
      setLoadedBlogIndexCurr(0);
      systemSkip = true;
      goToSlide(0);
      return;
    }
    forceRerender();

    setLoadedBlogIndexStart(id - 2);
    setLoadedBlogIndexCurr(id - 1);
    setLoadedBlogIndexEnd(id + 1);
    setTimeout(() => {
      goToSlide(1);
    }, 10);
  };

  const handleNextTransitionStart = (swiper) => {
    if (blogs.length === 0) {
      return;
    }

    if (systemSkip) {
      systemSkip = false;
      return;
    }

    if (loadedBlogIndexCurr === 0) {
      setLoadedBlogIndexCurr(loadedBlogIndexCurr + 1);
      setLoadedBlogIndexEnd(loadedBlogIndexEnd + 1);
      return;
    }

    if (loadedBlogIndexCurr === blogs.length - 1) {
      return;
    }
    setLoadedBlogIndexStart(loadedBlogIndexStart + 1);
    setLoadedBlogIndexCurr(loadedBlogIndexCurr + 1);
    setLoadedBlogIndexEnd(loadedBlogIndexEnd + 1);
    goToSlide(1);
  };

  const handlePrevTransitionStart = (swiper) => {
    // Do nothing if it came from 2nd page
    if (loadedBlogIndexStart === 0) {
      return;
    }
    // Do nothing if come from skipToBlog to 1st page
    if (systemSkip) {
      systemSkip = false;
      return;
    }
    setLoadedBlogIndexStart(loadedBlogIndexStart - 1);
    setLoadedBlogIndexCurr(loadedBlogIndexCurr - 1);
    setLoadedBlogIndexEnd(loadedBlogIndexEnd - 1);
    setTimeout(() => goToSlide(1), 10);
  };

  //////////////// Scroll Reset Function ///////////////////////////
  const scrollRefs = useRef([]);

  const resetScrollPosition = (index) => {
    if (scrollRefs.current[index]) {
      scrollRefs.current[index].scrollTop = 0;
    }
  };

  const resetScrollPositionAll = () => {
    resetScrollPosition(0);
    resetScrollPosition(1);
    resetScrollPosition(2);
  };

  //////////////// Brief View Function ///////////////////////////

  const [showOverview, setShowOverview] = useState(false);
  const [showOverviewOnce, setShowOverviewOnce] = useState(false);
  const handleOverview = () => {
    setShowOverview(!showOverview);
  };

  // Show overview when first scrolled to the section
  useEffect(() => {
    const handleScroll = () => {
      const { offsetTop, offsetHeight } =
        document.getElementById("BlogSection");
      if (window.scrollY > offsetTop - offsetHeight / 6 && !showOverviewOnce) {
        setShowOverviewOnce(true);
        setShowOverview(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showOverviewOnce]);

  //////////////// Search Bar Function ///////////////////////////
  const [isExpanded, setIsExpanded] = useState(false); // State to toggle expanded/collapsed search bar
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.length > 0) {
      const results = blogs
        .map((blog) => ({
          ...blog,
          title: language === "English" ? blog.titleEN : blog.titleCN,
        }))
        .filter((blog) =>
          blog.title.toLowerCase().includes(query.toLowerCase())
        )
        .sort((a, b) => a.title.length - b.title.length) // Simple similarity measure
        .slice(0, 5);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  //////////////// FILTER ///////////////////////////
  const [showFilter, setShowFilter] = useState(false);
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTypes((prevSelectedTypes) => [...prevSelectedTypes, value]);
    } else {
      setSelectedTypes((prevSelectedTypes) =>
        prevSelectedTypes.filter((type) => type !== value)
      );
    }

    // Go to first page
    setLoadedBlogIndexStart(0);
    setLoadedBlogIndexEnd(2);
    setLoadedBlogIndexCurr(0);
  };

  //////////// Force Render Upon Theme Change ///////////////////////
  const [key, setKey] = useState(0);
  const forceRerender = () => setKey((prevKey) => prevKey + 1);
  useEffect(() => {
    forceRerender();
    setLoadedBlogIndexStart(0);
    setLoadedBlogIndexEnd(2);
    setLoadedBlogIndexCurr(0);
    setSearchQuery("");
    setSearchResults([]);
    setSelectedTypes([]);
    systemSkip = false;
  }, [theme]);

  return (
    <section id="BlogSection" className={`${styles.box} ${themeStyles.box}`}>
      <div className={styles.contentBox}>
        <div className={styles.blog_brief_container}>
          <div className={styles.centreBox}>
            <div
              className={`${styles.sectionTitle} ${themeStyles.sectionTitle}`}
            >
              {headingContent.title}
            </div>
            <div className={styles.blog_brief_description_container}>
              <p
                className={`${styles.sectionDescription} ${themeStyles.sectionDescription}`}
              >
                {wordsData.description}
              </p>
            </div>

            <div className={styles.latest_news_container}>
              <h2 className={styles.latest_news_title}>
                {headingContent.latestNewsTitle} ({wordsData.latestNewsDate}){" "}
              </h2>
              {wordsData.latestNews.map((paragraph, index) => (
                <p
                  key={index}
                  className={`${styles.latest_news_content} ${themeStyles.latest_news_content}`}
                >
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.blog_articles_container}>
          <div className={styles.searchItemSystemContainer}>
            {/* Overview Dropdown */}
            <div className={styles.overviewDropdown}>
              <button
                className={`${styles.overviewButton} ${themeStyles.overviewButton}`}
                onClick={handleOverview}
              >
                {!showOverview
                  ? headingContent.viewButton
                  : headingContent.closeButton}
              </button>
              <motion.div
                initial={{ height: 0, width: 0 }}
                animate={{
                  height: showOverview ? 415 : 0,
                  width: showOverview ? 300 : 0,
                  visibility: showOverview ? "visible" : "hidden",
                }}
                transition={{ duration: 0.5 }}
                exit={{ height: 0, width: 0 }}
                className={`${styles.overviewForm} ${themeStyles.overviewForm}`}
              >
                <div
                  className={`${styles.overviewContainer} ${themeStyles.overviewContainer}`}
                >
                  {blogs.map((blog, idx) => (
                    <button
                      className={`${styles.blogNavButton} ${themeStyles.blogNavButton}`}
                      key={idx}
                      onClick={() => {
                        skipToBlog(blog.Id);
                        setShowOverview(false);
                      }}
                    >
                      <span style={{ display: "inline-block", width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {language === "English" ? blog.titleEN : blog.titleCN}
                      </span>
                    </button>
                  ))}
                </div>
              </motion.div>
            </div>

            {/* Search Bar */}
            <div className={styles.pageNavContainer}>
              <div
                className={`${styles.searchBox} ${
                  isExpanded ? styles.expanded : styles.collapsed
                } ${themeStyles.searchBox}`}
              >
                <div
                  className={styles.searchOutline}
                  onClick={() => setIsExpanded(true)}
                >
                  <FontAwesomeIcon icon={fas.faMagnifyingGlass} />
                </div>
                {isExpanded && (
                  <>
                    <div
                      className={`${styles.searchInput} ${themeStyles.searchInput}`}
                    >
                      <input
                        type="text"
                        placeholder="Search within filter"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                    <div
                      className={styles.close}
                      onClick={() => setIsExpanded(false)}
                    >
                      <FontAwesomeIcon icon={fas.faXmark} />
                    </div>
                    {searchResults.length > 0 && (
                      <div
                        className={`${styles.searchResults} ${themeStyles.searchResults}`}
                      >
                        {searchResults.map((result, idx) => (
                          <div
                            key={idx}
                            className={styles.searchResult}
                            onClick={() => {
                              setIsExpanded(false);
                              skipToBlog(result.Id);
                            }}
                          >
                            <div className={styles.searchMagGlass}>
                              {" "}
                              <FontAwesomeIcon
                                icon={fas.faMagnifyingGlass}
                              />{" "}
                            </div>
                            {result.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Filter Button */}
            <div
              className={`${styles.filterDropdown} ${themeStyles.filterDropdown}`}
            >
              <button
                className={`${styles.filterButton} ${themeStyles.filterButton}`}
                onClick={handleShowFilter}
              >
                {!showFilter
                  ? headingContent.filterButton
                  : headingContent.closeButton}
              </button>
              <motion.div
                initial={{ height: 0 }}
                animate={{
                  height: showFilter ? "auto" : 0,
                  visibility: showFilter ? "visible" : "hidden",
                }}
                transition={{ duration: 0.5 }}
                exit={{ height: 0 }}
                className={`${styles.filterForm} ${themeStyles.filterForm}`}
              >
                <fieldset>
                  <legend>Type</legend>
                  {wordsData.filter.map((category, index) => (
                    <div key={index}>
                      <label>
                        <input
                          type="checkbox"
                          value={category.value}
                          onChange={handleCheckboxChange}
                        />
                        <div className={styles.filterDisplay}>
                          {category.display}
                        </div>
                      </label>
                    </div>
                  ))}
                </fieldset>
              </motion.div>
            </div>
          </div>

          {/* Swiper for Past Blog */}
          <Swiper
            className={`${styles.swiper} ${themeStyles.swiper}`}
            key={key}
            modules={[Navigation, Pagination]}
            navigation={true}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            breakpoints={{
              769: { slidesPerView: 1, slidesPerGroup: 1 },
            }}
            onReachEnd={(swiper) => {
              setTimeout(() => {
                // Go back to middle slide and reset scroll position after 4 seconds
                resetScrollPositionAll();
                handleNextTransitionStart(swiper);
              }, 400);
            }}
            onReachBeginning={(swiper) => {
              setTimeout(() => {
                resetScrollPositionAll();
                handlePrevTransitionStart(swiper);
              }, 400);
            }}
          >
            {displayedBlog.map((blog, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`${styles.pastBlogTitle} ${themeStyles.pastBlogTitle}`}
                >
                  {language === "English" ? blog.titleEN : blog.titleCN}
                </div>
                <div
                  ref={(el) => (scrollRefs.current[index] = el)}
                  className={`${styles.scrollableContainer} ${themeStyles.scrollableContainer}`}
                >
                  <img
                    className={styles.pastBlogImage}
                    src={`/api/articles/${
                      language === "English" ? blog.urlEN : blog.urlCN
                    }`}
                    alt="Pasts Blog"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default PastBlog;
