import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Image3 = () => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get('https://australianwallstreet.com/api/randomimages?publicPath=image3');
        if (response.data.length > 0) {
          setImageUrl(`https://australianwallstreet.com/api/public/${response.data[0].imagePath}`);
        }
      } catch (error) {
        console.error('Error fetching image3:', error);
      }
    };
    
    fetchImage();
  }, []);

  return imageUrl ? (
    <img src={imageUrl} alt="Public Image 3" style={{ maxWidth: '100%' }} />
  ) : null;
};

export default Image3;
