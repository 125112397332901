import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import memberWords from "./words.json";
import { useLanguage } from "../index/ThemeContext";

const primaryColor = "#2d2d2d";
const secondaryColor = "#1a1a1a";
const accentColor = "#4a4a4a";

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8f9fa",
    padding: "2rem",
  },
  card: {
    background: "white",
    borderRadius: "20px",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
    maxWidth: "800px",
    width: "100%",
    overflow: "hidden",
  },
  header: {
    background: "linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)",
    color: "#212529",
    padding: "2.5rem",
    textAlign: "center",
    borderBottom: "1px solid #dee2e6",
  },
  formContainer: {
    padding: "2.5rem",
  },
  sectionTitle: {
    fontSize: "1.25rem",
    fontWeight: "600",
    color: "#1f2937",
    marginBottom: "1rem",
    position: "relative",
    paddingLeft: "1rem",
  },
  sectionTitleLine: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    height: "60%",
    width: "4px",
    backgroundColor: "#2d2d2d",
    borderRadius: "2px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "1.5rem",
    marginBottom: "1.5rem",
  },
  inputGroup: {
    marginBottom: "1rem",
  },
  label: {
    display: "block",
    fontSize: "0.875rem",
    color: "#374151",
    marginBottom: "0.5rem",
    fontWeight: "500",
    textAlign: "left",
  },
  input: {
    width: "100%",
    maxWidth: "100%",
    padding: "1rem",
    border: `1px solid ${accentColor}`,
    borderRadius: "8px",
    fontSize: "1.1rem",
    transition: "all 0.3s ease",
    boxSizing: "border-box",
    minHeight: "48px",
    "&:hover": {
      borderColor: primaryColor,
    },
    "&:focus": {
      borderColor: "#2d2d2d",
      boxShadow: "0 0 0 3px rgba(45, 45, 45, 0.15)",
    },
  },
  inputFocus: {
    borderColor: "#6366f1",
    boxShadow: "0 0 0 3px rgba(99, 102, 241, 0.15)",
  },
  radioGroup: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginTop: "0.5rem",
    height: "40px",
  },
  radioLabel: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "0.875rem",
    cursor: "pointer",
    height: "100%",
  },
  radioInput: {
    width: "16px",
    height: "16px",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: accentColor,
    borderRadius: "50%",
    position: "relative",
    transition: "all 0.2s ease",
    "&:hover": {
      borderColor: primaryColor,
    },
  },
  radioChecked: {
    borderColor: primaryColor,
  },
  radioDot: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: primaryColor,
    opacity: 0,
    transition: "opacity 0.2s ease",
  },
  uploadArea: {
    border: `2px dashed ${accentColor}`,
    borderRadius: "12px",
    padding: "1.5rem",
    textAlign: "center",
    backgroundColor: "#f9fafb",
    marginTop: "1rem",
    maxWidth: "500px",
    margin: "1rem auto",
    display: "flex",
    flexDirection: "column",
    gap: "0.75rem",
    fontSize: "1.1rem",
    "&:hover": {
      borderColor: primaryColor,
    },
  },
  bankInfo: {
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    padding: "0.75rem",
    marginBottom: "0.75rem",
    border: `1px solid ${accentColor}`,
  },
  bankInfoTitle: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "#1f2937",
    marginBottom: "1rem",
    paddingBottom: "0.5rem",
    borderBottom: `1px solid ${accentColor}`,
  },
  bankInfoItem: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "0.5rem",
    fontSize: "0.875rem",
    lineHeight: "1.5",
  },
  bankInfoLabel: {
    color: "#4b5563",
    minWidth: "60px",
    marginRight: "0.5rem",
    flexShrink: 0,
  },
  bankInfoValue: {
    color: "#1f2937",
    fontWeight: "500",
    flexGrow: 1,
    textAlign: "left",
    wordBreak: "break-word",
  },
  submitButton: {
    width: "100%",
    padding: "1rem",
    background: "linear-gradient(135deg, #2d2d2d 0%, #1a1a1a 100%)",
    color: "white",
    border: "none",
    borderRadius: "8px",
    fontSize: "1rem",
    fontWeight: "600",
    cursor: "pointer",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
  },
  error: {
    backgroundColor: "#f5f5f5",
    color: "#d32f2f",
    padding: "1rem",
    borderRadius: "8px",
    marginBottom: "1.5rem",
    border: `1px solid #d32f2f`,
  },
  "@media (max-width: 480px)": {
    header: {
      padding: "1.5rem",
    },
    formContainer: {
      padding: "1.5rem",
    },
    bankInfo: {
      padding: "0.875rem",
    },
    bankInfoItem: {
      flexDirection: "row",
      alignItems: "center",
    },
    bankInfoLabel: {
      minWidth: "50px",
      marginBottom: 0,
    },
    bankInfoValue: {
      whiteSpace: "normal",
      "& > span:last-child": {
        display: "block",
        marginLeft: 0,
        marginTop: "0.25rem",
      },
    },
    previewContainer: {
      width: "100%",
      padding: "0.75rem",
      border: `1px solid ${accentColor}`,
    },
    previewName: {
      fontSize: "0.875rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    removeButton: {
      top: "4px",
      right: "4px",
    },
    input: {
      padding: "0.875rem",
      fontSize: "1rem",
      minHeight: "44px",
    },
  },
  noteBox: {
    backgroundColor: "#fff4e6",
    borderRadius: "8px",
    padding: "0.75rem",
    border: "2px solid #ffd8a8",
    marginBottom: "0.75rem",
  },
  noteContent: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  noteTitle: {
    fontWeight: "600",
    color: "#d9480f",
    fontSize: "0.95rem",
  },
  highlight: {
    color: "#d9480f",
    fontWeight: "500",
    fontSize: "1.1rem",
  },
  noteExample: {
    color: "#d9480f",
    opacity: 0.8,
    fontSize: "0.9rem",
    marginLeft: "1rem",
  },
  previewContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    padding: "1rem",
    backgroundColor: "#f3f4f6",
    borderRadius: "8px",
    width: "calc(100% - 2px)",
    margin: "0 auto",
    position: "relative",
    boxSizing: "border-box",
  },
  previewIcon: {
    fontSize: "2rem",
  },
  previewInfo: {
    flexGrow: 1,
    textAlign: "left",
  },
  previewName: {
    fontWeight: "500",
    color: "#1f2937",
    marginBottom: "0.25rem",
  },
  previewSize: {
    color: "#6b7280",
    fontSize: "0.875rem",
  },
  removeButton: {
    position: "absolute",
    top: "4px",
    right: "4px",
    background: "#ef4444",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    transition: "all 0.2s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  removeIcon: {
    fontSize: "14px",
    lineHeight: 1,
  },
  requiredStar: {
    color: "#ff4d4f",
    marginLeft: "4px",
    fontSize: "0.8em",
  },
  formNotice: {
    color: "#666",
    fontSize: "0.9rem",
    marginBottom: "1.5rem",
    textAlign: "center",
    padding: "0.5rem",
    borderBottom: "2px solid #f0f0f0",
  },
  select: {
    paddingRight: "2.5rem",
    fontSize: "1.1rem",
    minHeight: "48px",
  },
  societyHint: {
    color: "#ff4d4f",
    fontSize: "0.875rem",
    marginTop: "0.5rem",
    textAlign: "left",
  },
  loadingSpinner: {
    border: "3px solid rgba(255, 255, 255, 0.3)",
    borderTop: "3px solid #fff",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    animation: "spin 1s linear infinite",
    marginRight: "8px",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

function Signup() {
  const [formData, setFormData] = useState({
    selectedSociety: "",
    fullName: "",
    preferredName: "",
    email: "",
    phone: "",
    wechatID: "",
    uniID: "",
    graduationYear: "2025",
    degree: "",
    isArcMember: true,
    referralSource: "",
    graduatedUniversity: "",
    otherUniversity: "",
    profession: "",
    otherProfession: "",
    yearsOfExperience: "",
    approvalKey: "",
  });
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { language } = useLanguage();
  const words = memberWords[language].registrationForm;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const formPayload = new FormData();
    formPayload.append("selectedSociety", formData.selectedSociety);
    formPayload.append("fullName", formData.fullName);
    formPayload.append("preferredName", formData.preferredName);
    formPayload.append("email", formData.email);
    formPayload.append("phone", formData.phone);
    formPayload.append("wechatID", formData.wechatID);

    if (formData.selectedSociety === "AWS UNSW") {
      formPayload.append("uniID", formData.uniID);
      formPayload.append("graduationYear", formData.graduationYear);
      formPayload.append("degree", formData.degree);
      formPayload.append("isArcMember", formData.isArcMember.toString());
    } else if (formData.selectedSociety === "AWS Pro") {
      formPayload.append(
        "graduatedUniversity",
        formData.graduatedUniversity === "Others"
          ? formData.otherUniversity
          : formData.graduatedUniversity
      );
      formPayload.append(
        "profession",
        formData.profession === "Others"
          ? formData.otherProfession
          : formData.profession
      );
      formPayload.append("yearsOfExperience", formData.yearsOfExperience);
      formPayload.append("approvalKey", formData.approvalKey);
    }

    formPayload.append("referralSource", formData.referralSource);

    if (file && formData.selectedSociety !== "AWS Pro") {
      formPayload.append("receipt", file);
    }
    console.log("提交数据：", Object.fromEntries(formPayload));

    try {
      const response = await axios.post(
        "https://australianwallstreet.com/api/member/signup",
        formPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const loginResponse = await axios.post(
        "https://australianwallstreet.com/api/member/login",
        { phone: formData.phone }
      );

      localStorage.setItem("currentMemberId", loginResponse.data.id);
      navigate("/member/dashboard");
    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || "请求失败，请检查网络连接");
      } else {
        setError("服务器未响应，请稍后重试");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.header}>
          <h2
            style={{
              fontSize: "2rem",
              marginBottom: "0.5rem",
              color: "#343a40",
              textShadow: "none",
            }}
          >
            {words.title}
          </h2>
          <p
            style={{
              opacity: 0.8,
              color: "#495057",
            }}
          >
            {words.subtitle}
          </p>
        </div>

        <div style={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            {/* 社团选择 */}
            <div style={{ marginBottom: "2rem" }}>
              <div style={styles.sectionTitle}>
                <div style={styles.sectionTitleLine} />
                {words.societySection}
              </div>
              <div style={styles.grid}>
                <div style={styles.inputGroup}>
                  <div style={{ position: "relative" }}>
                    <select
                      value={formData.selectedSociety}
                      style={{
                        ...styles.input,
                        appearance: "none",
                        paddingRight: "2.5rem",
                      }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          selectedSociety: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="" disabled hidden>
                        {words.selectOption}
                      </option>
                      <option value="AWS UNSW">AWS UNSW</option>
                      <option value="AWS Pro">AWS Pro</option>
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        color: "#6b7280",
                        fontSize: "0.8em",
                      }}
                    >
                      ▼
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 基本信息 */}
            <div style={{ marginBottom: "2rem" }}>
              <div style={styles.sectionTitle}>
                <div style={styles.sectionTitleLine} />
                {words.basicInfo}
              </div>
              <div style={styles.grid}>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    {words.fields.fullName}
                    <span style={styles.requiredStar}>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    style={styles.input}
                    onChange={(e) =>
                      setFormData({ ...formData, fullName: e.target.value })
                    }
                  />
                </div>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    {words.fields.email}
                    <span style={styles.requiredStar}>*</span>
                  </label>
                  <input
                    type="email"
                    required
                    style={styles.input}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    {words.fields.phone}
                    <span style={styles.requiredStar}>*</span>
                  </label>
                  <input
                    type="tel"
                    required
                    value={formData.phone}
                    style={styles.input}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                </div>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    {words.fields.preferredName}
                  </label>
                  <input
                    type="text"
                    style={styles.input}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        preferredName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            {/* 社交媒体联系信息 */}
            <div style={{ marginBottom: "2rem" }}>
              <div style={styles.sectionTitle}>
                <div style={styles.sectionTitleLine} />
                {words.socialMediaSection}
              </div>
              <div style={styles.grid}>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    {words.fields.wechatID}
                    <span style={styles.requiredStar}>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    style={styles.input}
                    onChange={(e) =>
                      setFormData({ ...formData, wechatID: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            {/* 支付凭证 - 仅当选择非AWS Pro时显示 */}
            {formData.selectedSociety &&
              formData.selectedSociety !== "AWS Pro" && (
                <div style={{ marginBottom: "2rem" }}>
                  <div style={styles.sectionTitle}>
                    <div style={styles.sectionTitleLine} />
                    {words.paymentSection}
                  </div>
                  <div style={styles.uploadArea}>
                    {!file ? (
                      <>
                        <div style={styles.bankInfo}>
                          <div style={styles.bankInfoTitle}>
                            {words.bankInfo.title}
                          </div>
                          <div style={styles.bankInfoItem}>
                            <span style={styles.bankInfoLabel}>
                              {words.bankInfo.accountName}
                            </span>
                            <span style={styles.bankInfoValue}>AWS</span>
                          </div>
                          <div style={styles.bankInfoItem}>
                            <span style={styles.bankInfoLabel}>
                              {words.bankInfo.bsb}
                            </span>
                            <span style={styles.bankInfoValue}>062-188</span>
                          </div>
                          <div style={styles.bankInfoItem}>
                            <span style={styles.bankInfoLabel}>
                              {words.bankInfo.accountNumber}
                            </span>
                            <span style={styles.bankInfoValue}>1077 6005</span>
                          </div>
                          <div style={styles.bankInfoItem}>
                            <span style={styles.bankInfoLabel}>
                              {words.bankInfo.amount}
                            </span>
                            <span style={styles.bankInfoValue}>
                              $10 ($15 if not Arc Member, $20 if not UNSW)
                            </span>
                          </div>
                        </div>
                        <div style={styles.noteBox}>
                          <div style={styles.noteContent}>
                            <div style={styles.noteTitle}>
                              {words.paymentNote.title}
                            </div>
                            <div style={styles.noteText}>
                              <span style={styles.highlight}>
                                {words.paymentNote.text}
                              </span>
                              <span style={styles.noteExample}>
                                ({words.paymentNote.example})
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div style={styles.previewContainer}>
                        <div style={styles.previewIcon}>📄</div>
                        <div style={styles.previewInfo}>
                          <div style={styles.previewName}>{file.name}</div>
                          <div style={styles.previewSize}>
                            {(file.size / 1024).toFixed(1)} KB
                          </div>
                        </div>
                        <button
                          type="button"
                          style={styles.removeButton}
                          onClick={() => {
                            setFile(null);
                            document.getElementById("fileUpload").value = "";
                          }}
                        >
                          <span style={styles.removeIcon}>×</span>
                        </button>
                      </div>
                    )}

                    <input
                      type="file"
                      accept=".pdf,.jpg,.png"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="fileUpload"
                    />
                    {!file && (
                      <label htmlFor="fileUpload" style={{ cursor: "pointer" }}>
                        <div
                          style={{ fontSize: "3rem", marginBottom: "0.5rem" }}
                        >
                          📁
                        </div>
                        <div style={{ fontWeight: 500, color: "#6366f1" }}>
                          {words.fileUpload.label}
                        </div>
                        <p
                          style={{
                            color: "#6b7280",
                            fontSize: "0.875rem",
                            marginTop: "0.5rem",
                          }}
                        >
                          {words.fileUpload.formats}
                        </p>
                      </label>
                    )}
                  </div>
                </div>
              )}

            {/* 学校信息 */}
            {formData.selectedSociety === "AWS UNSW" && (
              <div style={{ marginBottom: "2rem" }}>
                <div style={styles.sectionTitle}>
                  <div style={styles.sectionTitleLine} />
                  {words.educationSection}
                </div>
                <div style={styles.grid}>
                  <div style={styles.inputGroup}>
                    <label style={styles.label}>
                      {words.fields.uniID}
                      <span style={styles.requiredStar}>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      style={styles.input}
                      onChange={(e) =>
                        setFormData({ ...formData, uniID: e.target.value })
                      }
                    />
                  </div>
                  <div style={styles.inputGroup}>
                    <label style={styles.label}>
                      {words.fields.graduationYear}
                      <span style={styles.requiredStar}>*</span>
                    </label>
                    <select
                      value={formData.graduationYear}
                      style={styles.input}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          graduationYear: e.target.value,
                        })
                      }
                    >
                      {Array.from({ length: 17 }, (_, i) => 2024 + i).map(
                        (year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div style={styles.inputGroup}>
                    <label style={styles.label}>
                      {words.fields.degree}
                      <span style={styles.requiredStar}>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      style={styles.input}
                      onChange={(e) =>
                        setFormData({ ...formData, degree: e.target.value })
                      }
                    />
                  </div>
                  <div style={styles.inputGroup}>
                    <label style={styles.label}>
                      {words.fields.isArcMember}
                    </label>
                    <div style={styles.radioGroup}>
                      {[true, false].map((value) => (
                        <label key={value} style={styles.radioLabel}>
                          <div
                            style={{
                              ...styles.radioInput,
                              ...(formData.isArcMember === value &&
                                styles.radioChecked),
                            }}
                          >
                            <div
                              style={{
                                ...styles.radioDot,
                                opacity: formData.isArcMember === value ? 1 : 0,
                              }}
                            />
                          </div>
                          <input
                            type="radio"
                            name="arcMember"
                            value={value}
                            checked={formData.isArcMember === value}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                isArcMember: e.target.value === "true",
                              })
                            }
                            style={{ display: "none" }}
                          />
                          <span>{value ? "Yes" : "No"}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* 专业信息 - 仅当选择AWS Pro时显示 */}
            {formData.selectedSociety === "AWS Pro" && (
              <div style={{ marginBottom: "2rem" }}>
                <div style={styles.sectionTitle}>
                  <div style={styles.sectionTitleLine} />
                  {words.professionalSection || "专业信息"}
                </div>
                <div style={styles.grid}>
                  <div style={styles.inputGroup}>
                    <label style={styles.label}>
                      {words.fields.graduatedUniversity || "毕业院校"}
                      <span style={styles.requiredStar}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <select
                        value={formData.graduatedUniversity || ""}
                        style={{
                          ...styles.input,
                          appearance: "none",
                          paddingRight: "2.5rem",
                          display:
                            formData.graduatedUniversity === "Others"
                              ? "none"
                              : "block",
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData({
                            ...formData,
                            graduatedUniversity: value,
                            otherUniversity: "",
                          });
                        }}
                        required={formData.graduatedUniversity !== "Others"}
                      >
                        <option value="" disabled>
                          {words.selectOption}
                        </option>
                        <option value="UNSW">UNSW</option>
                        <option value="USYD">USYD</option>
                        <option value="UniMelb">UniMelb</option>
                        <option value="UTS">UTS</option>
                        <option value="MQU">MQU</option>
                        <option value="Others">Others</option>
                      </select>
                      {formData.graduatedUniversity === "Others" && (
                        <input
                          type="text"
                          required
                          placeholder="请输入院校名称"
                          style={styles.input}
                          value={formData.otherUniversity}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              otherUniversity: e.target.value,
                            })
                          }
                        />
                      )}
                      {formData.graduatedUniversity !== "Others" && (
                        <div
                          style={{
                            position: "absolute",
                            right: "1rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                            color: "#6b7280",
                            fontSize: "0.8em",
                          }}
                        >
                          ▼
                        </div>
                      )}
                    </div>
                    {formData.graduatedUniversity === "Others" && (
                      <div style={{ display: "flex", marginTop: "0.5rem" }}>
                        <button
                          type="button"
                          style={{
                            padding: "0.25rem 0.5rem",
                            fontSize: "0.75rem",
                            backgroundColor: "#f3f4f6",
                            border: "1px solid #d1d5db",
                            borderRadius: "0.25rem",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setFormData({
                              ...formData,
                              graduatedUniversity: "",
                              otherUniversity: "",
                            })
                          }
                        >
                          返回选择
                        </button>
                      </div>
                    )}
                  </div>
                  <div style={styles.inputGroup}>
                    <label style={styles.label}>
                      {words.fields.profession || "专业领域"}
                      <span style={styles.requiredStar}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <select
                        value={formData.profession || ""}
                        style={{
                          ...styles.input,
                          appearance: "none",
                          paddingRight: "2.5rem",
                          display:
                            formData.profession === "Others" ? "none" : "block",
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData({
                            ...formData,
                            profession: value,
                            otherProfession: "",
                          });
                        }}
                        required={formData.profession !== "Others"}
                      >
                        <option value="" disabled>
                          {words.selectOption}
                        </option>
                        <option value="Banking & Financial Services">
                          Banking & Financial Services
                        </option>
                        <option value="Investment & Asset Management">
                          Investment & Asset Management
                        </option>
                        <option value="Accounting & Auditing">
                          Accounting & Auditing
                        </option>
                        <option value="Insurance & Risk Management">
                          Insurance & Risk Management
                        </option>
                        <option value="Financial Advisory & Planning">
                          Financial Advisory & Planning
                        </option>
                        <option value="Trading & Market Operations">
                          Trading & Market Operations
                        </option>
                        <option value="FinTech & Technology">
                          FinTech & Technology
                        </option>
                        <option value="Treasury & Corporate Finance">
                          Treasury & Corporate Finance
                        </option>
                        <option value="Regulatory & Government">
                          Regulatory & Government
                        </option>
                        <option value="Economic & Market Research">
                          Economic & Market Research
                        </option>
                        <option value="Consulting & Strategy">
                          Consulting & Strategy
                        </option>
                        <option value="Others">Others</option>
                      </select>
                      {formData.profession === "Others" && (
                        <input
                          type="text"
                          required
                          placeholder="请输入专业领域"
                          style={styles.input}
                          value={formData.otherProfession}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              otherProfession: e.target.value,
                            })
                          }
                        />
                      )}
                      {formData.profession !== "Others" && (
                        <div
                          style={{
                            position: "absolute",
                            right: "1rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                            color: "#6b7280",
                            fontSize: "0.8em",
                          }}
                        >
                          ▼
                        </div>
                      )}
                    </div>
                    {formData.profession === "Others" && (
                      <div style={{ display: "flex", marginTop: "0.5rem" }}>
                        <button
                          type="button"
                          style={{
                            padding: "0.25rem 0.5rem",
                            fontSize: "0.75rem",
                            backgroundColor: "#f3f4f6",
                            border: "1px solid #d1d5db",
                            borderRadius: "0.25rem",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setFormData({
                              ...formData,
                              profession: "",
                              otherProfession: "",
                            })
                          }
                        >
                          返回选择
                        </button>
                      </div>
                    )}
                  </div>
                  <div style={styles.inputGroup}>
                    <label style={styles.label}>
                      {words.fields.yearsOfExperience || "专业经验年限"}
                      <span style={styles.requiredStar}>*</span>
                    </label>
                    <select
                      value={formData.yearsOfExperience || ""}
                      style={styles.input}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          yearsOfExperience: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="" disabled>
                        {words.selectOption}
                      </option>
                      {Array.from({ length: 40 }, (_, i) => i + 1).map(
                        (year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            )}

            {/* 推荐来源 */}
            <div style={{ marginBottom: "2rem" }}>
              <div style={styles.sectionTitle}>
                <div style={styles.sectionTitleLine} />
                {words.referralQuestion}
              </div>
              <div style={styles.grid}>
                <div style={styles.inputGroup}>
                  <label style={styles.label}>
                    {words.fields.referralSource}
                    <span style={styles.requiredStar}>*</span>
                  </label>
                  <div style={{ position: "relative" }}>
                    <select
                      value={formData.referralSource}
                      style={{
                        ...styles.input,
                        appearance: "none",
                        paddingRight: "2.5rem",
                      }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          referralSource: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="" disabled>
                        {words.selectOption}
                      </option>
                      {words.referralOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.display}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        color: "#6b7280",
                        fontSize: "0.8em",
                      }}
                    >
                      ▼
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* AWS Pro批准密钥 */}
            {formData.selectedSociety === "AWS Pro" && (
              <div style={{ marginBottom: "2rem" }}>
                <div style={styles.sectionTitle}>
                  <div style={styles.sectionTitleLine} />
                  {words.approvalSection || "批准信息"}
                </div>
                <div style={styles.grid}>
                  <div style={styles.inputGroup}>
                    <label style={styles.label}>
                      {words.fields.approvalKey || "批准密钥"}
                      <span style={styles.requiredStar}>*</span>
                    </label>
                    <input
                      type="text"
                      required
                      style={styles.input}
                      value={formData.approvalKey}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          approvalKey: e.target.value,
                        })
                      }
                      placeholder="请输入批准密钥"
                    />
                  </div>
                </div>
              </div>
            )}

            {error && <div style={styles.error}>{error}</div>}

            <button
              type="submit"
              style={styles.submitButton}
              onMouseOver={(e) =>
                isLoading
                  ? null
                  : (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseOut={(e) =>
                isLoading
                  ? null
                  : (e.currentTarget.style.transform = "scale(1)")
              }
              disabled={isLoading}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={styles.loadingSpinner}></div>
                  {words.submitLoading}
                </div>
              ) : (
                words.submitButton
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
